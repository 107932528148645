import React from "react";
import CertificateCard from "../components/Certificates/CertificateCard";
import { Col, Container, Row } from "react-bootstrap";

const Certificates = () => {
  const certificateDatas = [
    {
      name:"Nihad Maharramov",
      header:"Honorable Mention",
      desc: "Awarded for achievements in the field of education and services to the promotion of the university.",
      img: require("../assets/certificates/-3.jpg"),
    },
    {
      name:"Zangazur UAV",
      header:"Certificate of Gratitude",
      desc: "We sincerely appreciate your significant contribution and active involvement in the 'National Military Industry of the Future' startup project.",
      img: require("../assets/certificates/-2.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Award Certificate",
      desc: "A student of BMU's engineering faculty, was awarded the title of Brand Student.",
      img: require("../assets/certificates/-1.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Certificate",
      desc: "This certificate is presented to Nihad Maharramov for presenting his co-founded startup 'RED' at the demo-exhibition of startups organized in the Technopark of Baku Engineering University.",
      img: require("../assets/certificates/0.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Diploma",
      desc: "Awarded for winning second place in the 'Innovative Methods in Education Development II' competition dedicated to the 101st anniversary of the birth of national leader Heydar Aliyev, initiated by the Education Development Fund.",
      img: require("../assets/certificates/1.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Certificate",
      desc: "For the invaluable contribution as PARTICIPANT in the 1 International FICS 2024 Azerbaijan Pitching Event organized in cooperation with Azerbaijan Technical University.",
      img: require("../assets/certificates/2.jpg"),
      decs:""
    },
    {
      name:"Nihad Maharramov",
      header:"Certificate",
      desc: "We thank you for your participation in the 'Robotex Türkiye European Championship' held in the city of Antalya on 20-21 April 2024 of the 'Fibonacci International Robot Olympiad'. We will invite you again in the 'Fibonacci Robot Olympiad Azerbaijan 2025' event that we will organize in the city of Baku. We will be pleased to see you among us.",
      img: require("../assets/certificates/3.jpg"),
    },
    {
      name:"A-MAZE Team",
      header:"Certificate of First place",
      desc: "Certificate of Apprecation in Turkey Championship.",
      img: require("../assets/certificates/4.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Honorable Mention",
      desc: "Member of the 'A-MAZE' team of Baku Engineering University, student of Information Technology major (BEU-INHA DDP, 3rd year) for taking the first place in the 'Robotex Turkey Championship' festival.",
      img: require("../assets/certificates/5.jpg"),
    },
    {
      name:"A-MAZE Team",
      header:"Certificate of First place",
      desc: "Certificate of Apprecation in Robotex Azerbaijan - Eurasia Championship.",
      img: require("../assets/certificates/6.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Honorable Mention",
      desc: "A member of the 'A-MAZE' team of Baku Engineering University, a student of the Information Technology major (BEU-INHA DDP, 3rd year) for taking the first place in the 'Robotex Azerbaijan Eurasian Championship' festival.",
      img: require("../assets/certificates/7.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Certificate of Appreciation",
      desc: "Has provided exceptional training in Electronics class during 1-9 February 2024 in 'BoostCamp 7.0' project organized by BEU ICT Lab.",
      img: require("../assets/certificates/8.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Certificate of Appreciation",
      desc: "Has provided exceptional training in Microchip Programming class during 1-9 February 2024 in 'BoostCamp 7.0' project organized by BEU ICT Lab.",
      img: require("../assets/certificates/9.jpg"),
    },
    {
      name:"My Agri",
      header:"Certificate of acknowledge",
      desc: "Is awarded for its active participation in the 'Development of Agrarian Innovation and Startup Ecosystem'.",
      img: require("../assets/certificates/10.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Certificate of Completion",
      desc: "Has successfully complected 'Viveka Company - Creation Program'.",
      img: require("../assets/certificates/11.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Certificate of Participation",
      desc: "Has been awarded certificate of participation Code8 Hackhathon.",
      img: require("../assets/certificates/12.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Certificate of Participation",
      desc: "Has successfully participated in the 4th ABB Pre-Incubation program.",
      img: require("../assets/certificates/13.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Diploma",
      desc: "Team member who won the second place in the 'Mission: 2030' Hackathon by the 'Ministry of Digital Development and Transport' within the framework of 'Youth Sustainable Development Goals' implemented by the 'Ministry of Youth and Sports'.",
      img: require("../assets/certificates/14.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Certificate of Achievement",
      desc: "Has successfully participated in the 4th ABB 'Hackathon Startup Challange'.",
      img: require("../assets/certificates/15.jpg"),
    },
    {
      name:"Nihad Maharramov",
      header:"Certificate of Distinction",
      desc: "The Project of 'Increasing IT Skills of Youth' implemented with the support of 'Azerbaijan Youth Fund' and 'Ireli' Public Union has been successfully complected.",
      img: require("../assets/certificates/16.jpg"),
    },
  ];
  return (
    <Container fluid className="project-section">
        <Row style={{ justifyContent: "center", paddingBottom: "0px" }}>
      {certificateDatas.map((item, index) => (
        <Col lg={4} md={6} className="project-card">
          <CertificateCard data={item} key={index} />
        </Col>
      ))}
      </Row>
    </Container>
  );
};

export default Certificates;
