import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";

const CertificateCard = (props) => {
  return (
    <Card
      className="project-card-view"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignContent: "space-between",
      }}
    >
      <Card.Img
        variant="top"
        src={props.data.img}
        alt="card-img"
        style={{
        //   maxHeight: "350px",
          // height:"450px",
          objectFit: "contain",
          alignSelf: "flex-start",
          borderRadius: "10%",
        }}
      />
      <Card.Body
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap:10,
          alignContent: "space-between",
        }}
      >
        <Card.Title>{props.data.name}</Card.Title>
        <Card.Subtitle style={{color:'#fafbca'}}>{props.data.header}</Card.Subtitle>
        <Card.Text style={{color:'#ffbbff'}}>{props.data.desc}</Card.Text>
      </Card.Body>
    </Card>
  )
}

export default CertificateCard